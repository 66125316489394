<template>
  <div class="mt-2">
    <h1 class="mb-4">Termos e condições Balcão+</h1>
    <ProgressBar v-if="isLoading" mode="indeterminate" class="mb-4" />
    <ShowFile v-else-if="file" :file="file" pdfMaxWidth fullScreen />
  </div>
</template>

<script>
import ShowFile from "../files/ShowFile.vue";

export default {
  components: { ShowFile },
  data() {
    return {
      file: null,
      isLoading: false,
    };
  },
  mounted() {
    this.getTermsAndConditions();
  },
  methods: {
    getTermsAndConditions() {
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.api.fileManagerCardTermsAndConditions)
        .then((response) => {
          this.isLoading = false;
          this.file = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>