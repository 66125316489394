<template>
  <LoginBaseTemplate :title="$t('account_recovery')">
    <input
      v-model="email"
      :placeholder="$t('email')"
      type="text"
      class="p-2 mb-2 md:p-3 sm:mb-5"
      @keyup.enter="sendRecoverToken()"
    />
    <button
      type="button"
      class="p-button p-component justify-content-center"
      @click="sendRecoverToken()"
    >
      {{ $t("recover_account") }}
    </button>
    <ProgressBar v-if="isLoading" class="text-center" mode="indeterminate" />
    <InlineMessage v-if="messageSuccess" severity="success">
      {{ $t(messageSuccess) }}
    </InlineMessage>
    <InlineMessage v-if="messageError" severity="error">
      {{ $t(messageError) }}
    </InlineMessage>
    <p class="message text-center">
      <a @click="goToLogin">{{ $t("go_back") }}</a>
    </p>
  </LoginBaseTemplate>
</template>

<script>
import LoginBaseTemplate from "./LoginBaseTemplate.vue";
import InlineMessage from "primevue/inlinemessage";

export default {
  components: { LoginBaseTemplate, InlineMessage },
  extends: LoginBaseTemplate,
  data() {
    return {
      email: "",
      messageSuccess: null,
      messageError: null,
      hasEmail: true,
      SMSResponse: null,
      isLoading: false,
    };
  },
  methods: {
    sendRecoverToken() {
      this.messageError = this.messageSuccess = null;
      if (!this.email) {
        this.messageError = "email_is_required";
        return;
      }
      let credentials = {
        email: this.email,
      };
      this.isLoading = true;
      this.$root
        .apiPostUnauthenticated(this.$root.api.sendRecoverToken, credentials)
        .then(() => {
          this.isLoading = false;
          this.messageSuccess = "check_your_email_and_spam";
        })
        .catch((error) => {
          this.isLoading = false;
          this.messageError = error.response.data.msg_code;
        });
    },
    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
