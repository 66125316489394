<template>
  <ul class="mb-3 p-0 mt-0 mx-0 flex list-unstyled align-items-center">
    <li class="mr-4">
      <Button
        label="Voltar"
        class="p-button-sm"
        icon="pi pi-angle-double-left"
        @click="goBack"
      />
    </li>
    <li>
      <router-link :to="{ name: 'processes' }">{{
        $t("processes")
      }}</router-link>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3" />
    </li>
    <li>
      <span v-if="process.processTypeName" class="text-900 line-height-3">
        {{ process.code }} {{ process.processTypeName }} -
        {{ process.client.name }}
        <span v-if="process.client2 && process.client2.id">
          | {{ process.client2.name }}
        </span>
      </span>
    </li>
  </ul>
  <ProgressBar v-if="isLoading" mode="indeterminate" class="mb-4" />
  <h1
    v-if="process.processTypeName"
    class="md:pb-3 md:pt-3 md:mb-3 md:mt-0 pb-0"
  >
    {{ process.code }} {{ process.processTypeName }}
  </h1>
  <ProcessPosts :process-id="id" :process="process" @changed="getProcess" />
</template>

<script>
import ProcessPosts from "./ProcessPosts.vue";

export default {
  components: { ProcessPosts },
  props: { id: { type: [String, Number], default: null } },
  data() {
    return {
      process: {},
      isLoading: false,
    };
  },
  watch: {
    id() {
      this.getProcess();
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.getProcess();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getProcess() {
      this.process = {};
      this.isLoading = true;
      let params = { dataType: "page" };
      this.$root
        .apiGet(this.$root.getApiProcesses(this.id), { params })
        .then((response) => {
          this.isLoading = false;
          this.process = response.data.data;
          if (this.process.opportunityId) {
            this.getOpportunityFiles();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
          if (
            error.response.data.msg_code === "unauthorized" ||
            error.response.data.msg_code === "process_not_found"
          ) {
            this.$router.push({ name: "processes" });
          }
        });
    },
    getOpportunityFiles() {
      this.$root
        .apiGet(
          this.$root.getApiFileManagerOpportunity(this.process.opportunityId),
          { params: { dataType: "processFiles" } }
        )
        .then((response) => {
          this.process.opportunityFiles = response.data.data;
          this.process.files = this.process.files.concat(
            response.data.data[Object.keys(response.data.data)[0]]
          );
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>
