<template>
  <div>
    <FilesUploaded
      v-if="filesUploaded.length || filesPersisted.length"
      :files-uploaded="filesUploaded"
      :files-persisted="filesPersisted"
      :class-img-upload="classImgUpload"
      class="mb-3"
      @remove-file="removeFileByUrl"
    />
    <ProgressBar
      v-if="isLoading && uploadProgress !== 100"
      :value="uploadProgress"
      class="process-bar-separator mt-3 mb-3"
    />
    <ProgressBar
      v-if="isLoading && (uploadProgress === 100 || uploadProgress == null)"
      class="text-center mt-3 mb-3"
      mode="indeterminate"
    />

    <div class="grid justify-content-end">
      <FileUpload
        :id="uploaderId"
        :disabled="isLoading"
        :url="uploadUrl"
        :choose-label="$t('attach')"
        accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.txt,video/*"
        class="attach-files p-button-sm mr-3"
        name="files[]"
        mode="basic"
        auto
        multiple
        @select="onFileSelect"
        @before-send="beforeUpload"
        @upload="handleUpload"
        @error="handleError"
        @progress="setUploadProgress"
      />
      <Button
        :disabled="isLoading"
        :label="textBtn"
        class="p-button-sm"
        :class="{ 'button-publish': $root.user && $root.user.hasBalcaoMais }"
        @click="$parent.validateInputs"
      />
    </div>
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
import FilesUploaded from "../files/FilesUploaded.vue";

export default {
  components: { FilesUploaded, ProgressBar },
  props: {
    uploaderId: { type: String, required: true },
    uploadUrl: { type: String, required: true },
    maxFileSize: { type: Number, default: 20000000 }, // Tamanho padrão de 10MB
    textBtn: { type: String, default: "Publicar" },
    type: { type: String, default: "post" }, // tipo de ficheiro a fazer upload
    id: { type: Number, default: null }, // ID do POST
    classImgUpload: { type: String, default: null }, // ID do POST
  },
  emits: ["files-uploaded", "error", "progress"],
  data() {
    return {
      isLoading: false,
      filesUploaded: [],
      filesPersisted: [],
      uploadProgress: 0,
    };
  },
  methods: {
    setUploadProgress(event) {
      this.uploadProgress = event.progress;
      this.$emit("progress", event.progress);
    },
    onFileSelect(event) {
      const file = event.files[0];
      this.isLoading = false;
      if (file && file.size > this.maxFileSize) {
        this.$root.toastError(
          this.$t("error"),
          this.$t("max_file_size_allowed", {
            fileSize: `${this.maxFileSize / 1000000} MB`,
          })
        );
        this.canUpload = false;
        return;
      }
      this.canUpload = true;
    },
    beforeUpload(request) {
      if (!this.canUpload) {
        return false;
      }
      this.isLoading = true;
      return request.xhr.setRequestHeader(
        "Authorization",
        this.$root.axiosGetAuth()
      );
    },
    handleUpload(request) {
      this.isLoading = false;
      let files = JSON.parse(request.xhr.response).data;
      files.forEach((file) => {
        if (this.type == "post") {
          file.context = "pending_posts";
        } else if (this.type == "comment") {
          file.context = `post/${this.id}`;
        }
        this.filesUploaded.push(file);
      });
      this.$emit("files-uploaded", this.filesUploaded);
    },
    handleError() {
      this.isLoading = false;
      this.$emit("error");
    },
    removeFileByUrl(url) {
      this.filesUploaded = this.filesUploaded.filter(
        (file) => file.url !== url
      );
      this.filesPersisted = this.filesPersisted.filter(
        (file) => file.url !== url
      );
    },
  },
};
</script>
