<template>
  <li v-if="contacts">
    <div v-for="(category, key) in contacts" :key="key" class="mb-3">
      <div v-for="(contact, index) in category" :key="index">
        <div v-if="contact" class="flex flex-wrap">
          <div
            v-if="contact.functionDescription"
            class="title text-left mb-3 w-full"
          >
            <h4>{{ contact.functionDescription }}</h4>
          </div>
          <div class="flex container-info">
            <div v-if="key !== 'office'">
              <ShowUserImage
                :url="contact.imgUrl"
                img-class="rounded"
                width="40"
                height="40"
              />
            </div>
            <div
              class="flex flex-wrap align-items-center"
              :class="{ 'ml-4': key !== 'office' }"
            >
              <span v-if="contact.name">
                {{ contact.name }}
              </span>
              <small v-if="contact.phone" class="mt-2">
                <i class="pi pi-phone" />
                <span class="pl-2">
                  <a :href="`tel:${contact.phone}`" class="phone-link">
                    {{ contact.phone }}
                  </a>
                </span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { ShowUserImage },
  props: { contacts: { type: Object, default: null } },
};
</script>
