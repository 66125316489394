<template>
  <div>
    <img
      v-if="$root.fileIsImage(file)"
      :src="blobUrl"
      class="post-images"
      @click="showFile"
    />
    <div v-else-if="$root.fileIsMkvVideo(file)" class="text-center">
      <video class="post-videos" controls preload="auto" :src="blobUrl"></video>
    </div>
    <div v-else-if="$root.fileIsVideo(file)" class="text-center">
      <video class="post-videos" controls preload="auto" :src="blobUrl">
        <p>{{ $t("browser_does_not_support_html5_video") }}</p>
      </video>
    </div>

    <Button
      v-else-if="$root.fileIsPdf(file)"
      :label="displayName ? file.name : ''"
      :icon="displayName ? 'pi pi-file-pdf' : 'pi pi-search'"
      :class="displayName ? '' : 'p-button-success'"
      class="p-button-sm mr-2"
      type="button"
      @click="showFile"
    />
    <Button
      v-else
      :label="file.name"
      class="p-button-sm mr-2"
      type="button"
      icon="pi pi-file"
      @click="downloadFile(file.url, file.name)"
    />
    <ProgressBar v-if="isLoading" class="p-2" mode="indeterminate" />
  </div>
</template>

<script>
export default {
  props: { file: { type: Object, default: null }, displayName: Boolean },
  emits: ["show-file"],
  data() {
    return {
      isLoading: false,
      blobUrl: "",
    };
  },
  watch: {
    file() {
      if (
        this.$root.fileIsImage(this.file) ||
        this.$root.fileIsVideo(this.file) ||
        this.$root.fileIsMkvVideo(this.file)
      ) {
        this.loadFile();
      }
    },
  },
  mounted() {
    if (
      this.$root.fileIsImage(this.file) ||
      this.$root.fileIsVideo(this.file) ||
      this.$root.fileIsMkvVideo(this.file)
    ) {
      this.loadFile();
    }
  },
  methods: {
    loadFile() {
      const regex = /^(\/)?(tmp|dir|imagens)\//;
      const url = this.file.url;
      if (regex.test(url)) {
        this.blobUrl = this.$root.getUrlFromFilePath(url);
      } else {
        this.blobUrl = null;
        const filename = url.split("/").pop().split(".")[0];
        this.$root
          .apiGetFile(this.$root.getApiFileName(filename))
          .then((response) => {
            if (response && response.data instanceof Blob) {
              this.blobUrl = URL.createObjectURL(response.data);
            }
          });
      }
    },
    downloadFile(url, filename) {
      this.isLoading = true;
      this.$root
        .apiGetFile(this.$root.getUrlFromFileToDownload(url))
        .then((response) => {
          this.isLoading = false;
          this.$root.downloadFile(
            response.data,
            response.headers,
            filename,
            url
          );
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    showFile() {
      this.$emit("show-file");
    },
  },
};
</script>
