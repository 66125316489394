<template>
  <div id="appointments">
    <h1 v-if="dashboard == false">{{ $t("listAppointments") }}</h1>
    <div id="appoitments">
      <FullCalendar
        ref="fullcalendar"
        :options="calendarOptions"
        class="fullCalendar"
      >
        <template #eventContent="event">
          <div class="fc-event-container">
            <div class="fc-event-title">{{ event.event.title }}</div>
            <div class="fc-event-time-info flex align-items-center">
              <div class="fc-daygrid-event-dot mr-2"></div>
              <span
                >{{ formatTime(event.event.start) }} -
                {{ formatTime(event.event.end) }}</span
              >
            </div>
          </div>
        </template>
      </FullCalendar>
    </div>
    <Sidebar
      v-model:visible="showForm"
      :dismissable="false"
      class="p-sidebar-md appoitment-sidebar"
      position="right"
      header="Novo agendamento"
    >
      <h1>Formulário de Agendamento</h1>

      <div class="wrapper">
        <iframe
          ref="iframe"
          :src="`${appointmentsCreateService}${jwtToken}`"
          @load="isLoading = false"
        >
        </iframe>
      </div>
    </Sidebar>
  </div>
</template>
<script>
import "@fullcalendar/core";
import { ref } from "vue";
import ptLocale from "@fullcalendar/core/locales/pt";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/vue3";

export default {
  components: {
    FullCalendar,
  },
  props: {
    dashboard: {
      type: Boolean,
      default: false,
    },
    calendarView: {
      type: String,
      default: "dayGridMonth",
    },
  },
  data() {
    return {
      jwtToken: this.$root.axiosGetAuth(),
      isLoading: true,
      appointmentsCreateService: this.$root.api.appointmentsCreateService,
      appointments: [],
      showForm: false,
      startDate: null,
      idToEdit: null,
    };
  },
  computed: {
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, listPlugin, interactionPlugin, timeGridPlugin],
        locale: ptLocale,
        headerToolbar: this.dashboard
          ? false
          : {
              left: "prev,today,next",
              center: "title",
              right: "timeGridDay,timeGridWeek,dayGridMonth,listWeek",
            },
        initialView: this.calendarView,
        editable: false,
        selectable: true,
        events: this.appointments.map((appointment) => ({
          className:
            "custom-event " + appointment.type + " " + appointment.state,
          title: appointment.title,
          start: appointment.start,
          end: appointment.end,
          id: appointment.appointmentId,
        })),
        eventClick: this.handleEventClick,
        dateClick: this.handleDateSelect,
        height: "auto",
        weekNumbers: true,
        firstDay: 1,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
        },
        slotMinTime: "06:00:00",
        slotMaxTime: "20:00:00",
        weekNumberContent: (args) => {
          return args.num;
        },
        dayMaxEventRows: 3,
      };
    },
  },
  created() {
    this.getAppointments();
  },
  mounted() {
    window.addEventListener("message", this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    handleMessage(event) {
      // Verifique o conteúdo da mensagem e a origem, se necessário.
      if (event.data.action === "closeSidebar") {
        this.showForm = false; // Fecha o sidebar
      }
      if (event.data.type === "submit") {
        this.getAppointments();
      }
    },
    getAppointments() {
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.api.appointments, {
          params: { dataType: "calendar" },
        })
        .then((response) => {
          this.isLoading = false;
          this.appointments = response.data.data;

          // Atualize os eventos no FullCalendar
          const calendarApi = this.$refs.fullcalendar.getApi();
          calendarApi.removeAllEvents(); // Remova eventos antigos
          this.appointments.forEach((appointment) => {
            calendarApi.addEvent({
              className:
                "custom-event " + appointment.type + " " + appointment.state,
              title: appointment.title,
              start: appointment.start,
              end: appointment.end,
              id: appointment.appointmentId,
            });
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    handleEventClick(info) {
      const event = info.event;
      //console.log("Evento clicado:", event.title, event.id);
    },
    handleDateSelect(info) {
      //console.log("Data selecionada:", info.dateStr);
      this.setForm(true, info.dateStr, null);
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    setForm(show, date, appointmentId) {
      this.showForm = show;
      this.startDate = date;
      this.idToEdit = appointmentId;
    },
  },
};
</script>
