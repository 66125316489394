/* eslint-disable no-undef */
import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import { createI18n } from "vue-i18n";
import PrimeVue from "primevue/config";
import Vue3NativeNotification from "vue3-native-notification";
import Vue3Sanitize from "vue-3-sanitize";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "./assets/scss/base.scss";
import "./assets/scss/app.scss";
import "./assets/scss/dashboard.scss";
import "./assets/scss/appointments.scss";

import Tooltip from "primevue/tooltip";

import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Galleria from "primevue/galleria";
import ProgressBar from "primevue/progressbar";
import Dialog from "primevue/dialog";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import Checkbox from "primevue/checkbox";

const axiosInstance = require("axios");
const axios = axiosInstance.create();
const axiosExternalAuthWordpressBE = axiosInstance.create();
const uuidV4Regex =
  /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i;

import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const i18n = createI18n({
  locale: store.state.locale ? store.state.locale : "PT",
  fallbackLocale: ["PT", "EN"],
  messages: {
    PT: require("./locales/pt.json"),
    EN: require("./locales/en.json"),
  },
});

import dateFormat from "./mixins/dateFormat.js";
import apiRoutes from "./mixins/apiRoutes.js";
import fileValidator from "./mixins/fileValidator.js";
import imagesPath from "./mixins/imagesPath.js";
import dropdownOptions from "./mixins/dropdownOptions.js";
const logout = function (keepPage = true) {
  store.commit("clearToken");
  document.title = "Área de Cliente - Balcão do Emigrante";
  if (
    keepPage &&
    window.location.pathname !== "/" &&
    window.location.pathname !== "/login"
  ) {
    router
      .push({ name: "login", query: { next: window.location.pathname } })
      .catch(() => { });
  } else {
    router.push({ name: "login" }).catch(() => { });
  }
};

const jwtErrors = [
  "Expired JWT Token",
  "JWT Token not found",
  "unauthenticated",
  "Unauthorized",
];

const app = createApp({
  mixins: [dropdownOptions, apiRoutes, fileValidator, imagesPath, dateFormat],
  data() {
    return {
      user: {
        uuid: null,
        id: null,
        country: null,
        name: null,
        realm: null,
        cardId: null,
        imgUrl: null,
        hasCardPaymentAlert: null,
      },
      processEnv: process.env.NODE_ENV,
      emailRegex:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      news: [],
    };
  },
  created() {
    if (store.state.token) {
      this.axiosSetAuth(store.state.token);
    }
    this.setDateLocale("pt");
  },
  methods: {
    axiosGetAuth: () => axios.defaults.headers.common.Authorization,
    axiosSetAuth(token, isSecondTry) {
      if (!token) {
        delete axios.defaults.headers.common["Authorization"];
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        this.setCurrentUser(token);
        if (!isSecondTry) {
          axios.get(this.api.jwt).then((response) => {
            const newToken = response.data.data;
            if (newToken) {
              this.$store.commit("setToken", newToken);
              this.axiosSetAuth(newToken, true);
            }
          });
        }
      }
    },
    apiPostUnauthenticated: (url, data) => {
      return axios.post(url, data);
    },
    apiPutUnauthenticated: (url, data) => {
      return axios.put(url, data);
    },
    apiPost: (url, data) => {
      let post = axios.post(url, data);
      post.catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code == 401 &&
          jwtErrors.includes(error.response.data.message)
        ) {
          axios
            .post(process.env.VUE_APP_API_ADMIN + "logout")
            .then(() => logout(true))
            .catch(() => logout(true));
        }
      });
      return post;
    },
    apiGet: (url, data) => {
      let get = axios.get(url, data, {
        headers: { "Content-Type": "text/plain" },
      });
      get.catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code == 401 &&
          jwtErrors.includes(error.response.data.message)
        ) {
          axios
            .post(process.env.VUE_APP_API_ADMIN + "logout")
            .then(() => logout(true))
            .catch(() => logout(true));
        }
      });
      return get;
    },
    apiGetFile: (url, data) => {
      if (url.includes(process.env.VUE_APP_INTRAPAF)) {
        return axios.get(url);
      }

      if (data) {
        data.responseType = "blob";
        let get = axios.get(url, data).catch((error) => {
          if (
            error.response &&
            error.response.status == 401 &&
            "statusText" in error.response &&
            jwtErrors.includes(error.response.statusText)
          ) {
            axios
              .post(process.env.VUE_APP_API_ADMIN + "logout")
              .then(() => logout(true))
              .catch(() => logout(true));
          }
        });
        return get;
      }
      let get = axios.get(url, { responseType: "blob" }).catch((error) => {
        if (
          error.response &&
          error.response.status == 401 &&
          "statusText" in error.response &&
          jwtErrors.includes(error.response.statusText)
        ) {
          axios
            .post(process.env.VUE_APP_API_ADMIN + "logout")
            .then(() => logout(true))
            .catch(() => logout(true));
        }
      });
      return get;
    },
    apiPut: (url, data) => {
      let put = axios.put(url, data);
      put.catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code == 401 &&
          jwtErrors.includes(error.response.data.message)
        ) {
          axios
            .post(process.env.VUE_APP_API_ADMIN + "logout")
            .then(() => logout(true))
            .catch(() => logout(true));
        }
      });
      return put;
    },
    apiDelete: (url, data) => {
      let deleteApi = axios.delete(url, data);
      deleteApi.catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.code == 401 &&
          jwtErrors.includes(error.response.data.message)
        ) {
          axios
            .post(process.env.VUE_APP_API_ADMIN + "logout")
            .then(() => logout(true))
            .catch(() => logout(true));
        }
      });
      return deleteApi;
    },
    axiosWordpressBESetAuth(token) {
      if (!token) {
        delete axiosExternalAuthWordpressBE.defaults.headers.common[
          "Authorization"
        ];
      }
      axiosExternalAuthWordpressBE.defaults.headers.common.Authorization =
        "Bearer " + token;
    },
    apiWordpressBEGet: (url, data) =>
      axiosExternalAuthWordpressBE.get(url, data),
    logout(keepPage = false) {
      axios
        .post(process.env.VUE_APP_API_ADMIN + "logout")
        .then(() => logout(keepPage))
        .catch(() => logout(keepPage));
    },
    getUrlFromFilePath(filePath) {
      if (!filePath || filePath === this.images.defaultPicture) {
        return this.images.defaultPicture;
      }
      if (
        filePath.includes(window.location.hostname) ||
        filePath.startsWith(process.env.VUE_APP_ADMIN) ||
        filePath.startsWith("http")
      ) {
        return filePath;
      }
      const url =
        uuidV4Regex.test(filePath) || filePath.startsWith("/tmp/")
          ? process.env.VUE_APP_FILE_MANAGER + filePath
          : filePath.startsWith("/dir") || filePath.startsWith("dir")
            ? process.env.VUE_APP_INTRAPAF_CLIENT + filePath
            : process.env.VUE_APP_INTRAPAF + filePath;
      return url.replace(/([^:]\/)\/+/g, "$1");
    },
    getUrlFromFileToDownload(filePath) {
      if (filePath.charAt(0) == "/") {
        filePath = filePath.slice(1);
      }
      if (uuidV4Regex.test(filePath)) {
        return this.getApiFileName(filePath.split("/").pop());
      }
      if (
        filePath.includes(process.env.VUE_APP_INTRAPAF) ||
        filePath.includes(process.env.VUE_APP_INTRAPAF_CLIENT)
      ) {
        return filePath;
      }
      if (filePath.substring(0, 4) == "dir/") {
        return (
          process.env.VUE_APP_INTRAPAF_DOWNLOAD +
          process.env.VUE_APP_INTRAPAF_CLIENT +
          filePath
        );
      }
      return process.env.VUE_APP_INTRAPAF + filePath;
    },
    downloadFile(data, headers, filename, fileUrl) {
      let disposition = headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          let contentDispositionFilename = matches[1].replace(/['"]/g, "");
          if (
            !filename ||
            !uuidV4Regex.test(contentDispositionFilename.split(".")[0])
          ) {
            filename = contentDispositionFilename;
          }
        }
      }
      let link = document.createElement("a");
      let url = window.URL.createObjectURL(new Blob([data]));
      link.href = url;
      if (!filename) {
        filename = "download";
      } else if (!filename.includes(".") && fileUrl) {
        let type = fileUrl.split(".").pop();
        filename = `${filename}.${type}`;
      }
      link.setAttribute("download", filename);
      link.click();
      window.URL.revokeObjectURL(url);
    },
    setDefaultImage(event) {
      event.target.src = this.images.defaultPicture;
    },
    setDefaultFiletype(event) {
      event.target.src = this.images.defaultFiletype;
    },
    setCurrentUser(token) {
      let base64Payload = token.split(".")[1];
      let payload = this.decodeBase64(base64Payload);
      let payloadData = JSON.parse(payload.toString());

      this.user = {
        id: payloadData["id"],
        oldId: payloadData["old_id"],
        country: payloadData["country"],
        name: payloadData["name"],
        realm: payloadData["realm"],
        hasBalcaoMais: payloadData["hasBalcaoMais"],
        imgUrl: payloadData["imgUrl"],
        cardImageUrl: payloadData["cardImageUrl"],
      };
    },
    decodeBase64(base64) {
      try {
        // Primeiro, garante que a string está devidamente preenchida
        const normalizedBase64 = base64.replace(/-/g, '+').replace(/_/g, '/');
        const paddedBase64 = normalizedBase64.padEnd(
          normalizedBase64.length + ((4 - (normalizedBase64.length % 4)) % 4),
          '='
        );

        // Decodifica a string base64
        const decoded = atob(paddedBase64);

        // Converte para UTF-8
        return decodeURIComponent(
          Array.from(decoded)
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );
      } catch (error) {
        console.error('Erro ao decodificar base64:', error);
        return '{}'; // Retorna objeto vazio em caso de erro
      }
    },
    toastSuccess(title) {
      this.$toast.add({
        severity: "success",
        summary: this.$t(title),
        life: 5000,
      });
    },
    toastError(title) {
      this.$toast.add({
        severity: "error",
        summary: this.$t(title),
        life: 5000,
      });
    },
    toastWarning(summary, detail) {
      console.log(summary);
      console.log(detail);
      this.$toast.add({
        severity: "warn",
        summary: summary,
        detail: detail,
      });
    },
  },
  render: () => h(App),
});

app.use(Vue3NativeNotification, { requestOnNotify: true });
const overrideOptions = {
  allowedTags: [
    "address",
    "article",
    "aside",
    "footer",
    "header",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hgroup",
    "main",
    "nav",
    "section",
    "blockquote",
    "dd",
    "div",
    "dl",
    "dt",
    "figcaption",
    "figure",
    "hr",
    "li",
    "main",
    "ol",
    "p",
    "pre",
    "ul",
    "a",
    "abbr",
    "b",
    "bdi",
    "bdo",
    "br",
    "cite",
    "code",
    "data",
    "dfn",
    "em",
    "i",
    "kbd",
    "mark",
    "q",
    "rb",
    "rp",
    "rt",
    "rtc",
    "ruby",
    "s",
    "samp",
    "small",
    "span",
    "strong",
    "sub",
    "sup",
    "time",
    "u",
    "var",
    "wbr",
    "caption",
    "col",
    "colgroup",
    "table",
    "tbody",
    "td",
    "tfoot",
    "th",
    "thead",
    "tr",
    "iframe",
  ],
  allowedAttributes: {
    a: ["href", "name", "target"],
    iframe: ["src", "frameborder", "allowfullscreen"],
    img: ["src", "srcset", "alt", "title", "width", "height", "loading"],
  },
  allowedIframeHostnames: ["www.youtube.com"],
};
app.use(Vue3Sanitize, overrideOptions);

app.use(store);
app.use(router);
app.use(PrimeVue, {
  locale: {
    dayNames: i18n.global.messages[i18n.global.locale]["calendar"].dayNames,
    dayNamesShort:
      i18n.global.messages[i18n.global.locale]["calendar"].dayNamesShort,
    dayNamesMin:
      i18n.global.messages[i18n.global.locale]["calendar"].dayNamesMin,
    monthNames: i18n.global.messages[i18n.global.locale]["calendar"].monthNames,
    monthNamesShort:
      i18n.global.messages[i18n.global.locale]["calendar"].monthNamesShort,
    today: i18n.global.messages[i18n.global.locale]["calendar"].today,
    clear: i18n.global.messages[i18n.global.locale]["calendar"].clear,
    firstDayOfWeek: 1,
  },
});
app.use(i18n);
app.use(ConfirmationService);
app.use(ToastService);
app.directive("tooltip", Tooltip);
app.component("Toast", Toast);
app.component("Button", Button);
app.component("Sidebar", Sidebar);
app.component("InputText", InputText);
app.component("Textarea", Textarea);
app.component("FileUpload", FileUpload);
app.component("Galleria", Galleria);
app.component("ProgressBar", ProgressBar);
app.component("Dialog", Dialog);
app.component("Calendar", Calendar);
app.component("Dropdown", Dropdown);
app.component("Column", Column);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Checkbox", Checkbox);

app.config.productionTip = process.env.NODE_ENV !== "development";
app.config.devtools = false;
app.config.debug = false;
app.config.silent = true;
app.config.debug = process.env.NODE_ENV === "development";

app.mount("#app");
