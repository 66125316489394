const fileValidator = {
  data() {
    return {
      fileImageTypes: [
        "image",
        "apng",
        "bmp",
        "gif",
        "ico",
        "jpg",
        "jpeg",
        "jfif",
        "pjpeg",
        "pjp",
        "png",
        "svg",
        "webp",
      ],
      fileVideoTypes: ["video", "avi", "mpeg", "ogv", "webm", "ogg", "mp4"],
    };
  },
  methods: {
    fileIsPdf(file) {
      if (!file || !file.url) {
        return false;
      }
      let type = file.type ? file.type : file.url.split(".").pop();
      return type == "application/pdf" || type == "pdf";
    },
    fileIsImage(file) {
      if (!file) {
        return false;
      }
      let type = file.type
        ? file.type.split("/")[0]
        : file.url.split(".").pop();
      return this.fileImageTypes.includes(type.toLowerCase());
    },
    fileIsMkvVideo(file) {
      if (!file) {
        return false;
      }
      let type = file.type
        ? file.type.split("/")[0]
        : file.url.split(".").pop();
      return type.toLowerCase() == "mkv";
    },
    fileIsVideo(file) {
      if (!file) {
        return false;
      }
      let type = file.type
        ? file.type.split("/")[0]
        : file.url.split(".").pop();
      return this.fileVideoTypes.includes(type);
    },
    getFileTypeVideoSource(file) {
      return !file.type
        ? "video/" + file.url.split(".").pop()
        : file.type.includes("video")
        ? file.type
        : `video/${file.type}`;
    },
  },
};

export default fileValidator;
