const dropdownOptions = {
  data() {
    return {
      options: {
        phones: ["Telemóvel", "Fixo", "Trabalho", "Outro"],
        emails: ["Principal", "Secundário", "Trabalho", "Outro"],
        maritalStatus: [
          { label: "Solteiro(a)", value: "SINGLE" },
          { label: "Divorciado(a)", value: "DIVORCED" },
          { label: "Viúvo(a)", value: "WIDOWED" },
          {
            label: "Casado(a) - Comunhão de adquiridos",
            value: "MARRIED_ACQUIRED",
          },
          {
            label: "Casado(a) - Comunhão geral de bens",
            value: "MARRIED_GENERAL",
          },
          {
            label: "Casado(a) - Separação de bens",
            value: "MARRIED_SEPARATION",
          },
          {
            label: "Casado(a) - Outros regimes que os nubentes convencionem",
            value: "MARRIED_OTHER",
          },
        ],
      },
    };
  },
  methods: {},
};

export default dropdownOptions;
