<template>
  <div>
    <div class="flex col-12 p-0">
      <div class="flex mr-2">
        <ShowUserImage
          :url="$root.user.imgUrl"
          img-class="rounded mr-2 "
          width="30"
          height="30"
        />
      </div>
      <Textarea
        v-model="message"
        auto-resize
        :placeholder="$t('new_post_comment_placeholder')"
        class="mb-4 p-4"
        rows="3"
        cols="1"
        style="width: inherit"
      />
    </div>
    <FileUploader
      :id="id"
      :type="'comment'"
      :uploader-id="'uploadFilesForComment' + id"
      :upload-url="$root.api.fileManagerSave"
      :textbtn="$t('comment_post')"
      @files-uploaded="onFilesUploaded"
      @progress="setUploadProgress"
      @error="isLoading = false"
    />
  </div>
</template>

<script>
import FileUploader from "../utils/FileUploader.vue";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { FileUploader, ShowUserImage },
  props: { id: { type: Number, default: null } },
  emits: ["comment-created"],
  data() {
    return {
      message: "",
      isLoading: false,
      filesUploaded: [],
      filesPersisted: [],
      uploadProgress: 0,
    };
  },
  methods: {
    onFilesUploaded(files) {
      this.filesUploaded = files;
    },
    setUploadProgress(progress) {
      this.uploadProgress = progress;
    },
    validateInputs() {
      if (!this.message || this.message.length == 0) {
        this.$root.toastError("message_is_required");
      } else {
        this.isLoading = true;
        if (this.filesUploaded.length > 0) {
          this.persistFile();
        } else {
          this.createComment();
        }
      }
    },
    persistFile() {
      let files = {
        files: this.filesUploaded,
      };
      this.$root
        .apiPost(this.$root.api.fileManagerPersist, files)
        .then((response) => {
          this.filesUploaded = [];
          response.data.data.forEach((file) => {
            if (!file.url.startsWith("tmp/")) {
              this.filesPersisted.push(file);
            }
          });
          this.createComment();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    createComment() {
      let parameters = {
        message: this.message,
        attachments: this.filesPersisted,
        postId: this.id,
      };
      this.$root
        .apiPost(this.$root.api.postComments, parameters)
        .then((response) => {
          this.isLoading = false;
          this.$root.toastSuccess(response.data.msg_code);
          this.$emit("comment-created");
          this.resetData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetData() {
      this.isLoading = false;
      this.message = "";
      this.filesUploaded = this.filesPersisted = [];
    },
  },
};
</script>
