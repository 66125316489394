<template>
  <div id="news">
    <SidebarClient />
  </div>
</template>
<script>
import SidebarClient from "../dashboard/SidebarClient.vue";
export default {
  components: {
    SidebarClient,
  },
  data() {
    return {};
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {},
};
</script>
