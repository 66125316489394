<template>
  <div :class="showSidebar ? 'show-sidebar' : 'hide-sidebar'">
    <BlockUI :blocked="hasMissingData">
      <!--Navbar-->
      <div
        class="
          layout-wrapper layout-static layout-menu-light layout-topbar-blue
        "
      >
        <div
          class="layout-topbar"
          :class="{
            'layout-topbar-balcao-mais': $root.user && $root.user.hasBalcaoMais,
          }"
        >
          <button
            class="p-link layout-menu-button layout-topbar-icon"
            @click="setShowSidebar"
          >
            <i class="pi pi-bars" />
          </button>
          <router-link
            :to="{ name: 'dashboard' }"
            class="layout-topbar-logo inline"
          >
            <img
              :src="
                $root.user.hasBalcaoMais
                  ? $root.images.balcaoMaisLogo
                  : $root.images.beLogoTransparentBackground
              "
              alt="logo"
              class=""
            />
          </router-link>

          <ul class="topbar-menu">
            <li>
              <button class="p-link layout-topbar-icon" @click="confirmLogout">
                <i class="topbar-icon pi pi-fw pi-sign-out" />
              </button>
              <ConfirmDialog
                group="headless"
                :header="$t('logout_header')"
              ></ConfirmDialog>
            </li>
            <li>
              <button
                class="p-link layout-topbar-icon mr-1"
                @click="visibleProfile = true"
              >
                <i class="topbar-icon pi pi-fw pi-user" />
              </button>
            </li>
            <li>
              <button
                class="p-link layout-topbar-icon"
                @click="visibleNotifications = true"
              >
                <span class="p-overlay-badge">
                  <i class="topbar-icon pi pi-fw pi-bell" />
                  <span id="numberOfNotifications" class="p-badge">
                    {{ numberOfNotifications }}
                  </span>
                </span>
              </button>
            </li>

            <!-- <li class="navbar-user-name p-d-none p-d-md-inline-flex">
            {{ $root.user.name }}
          </li> -->
          </ul>
        </div>
        <Sidebar
          v-model:visible="showSmSidebar"
          :show-close-icon="false"
          class="layout-menu-container-sidebar"
          position="left"
          style="width: 250px"
          @hide="resetShowSidebar"
        >
          <DashboardSidebarMenu
            :contacts="contacts"
            @router-changed="resetShowSidebar"
          />
          <!--
          <SidebarClient />
          -->
        </Sidebar>
        <div class="layout-menu-container">
          <DashboardSidebarMenu :contacts="contacts" />
        </div>
        <div class="layout-main">
          <div class="layout-content">
            <div class="layout-dashboard">
              <Message
                v-if="hasCardPaymentAlert"
                severity="warn"
                @close="setHasCardPaymentAlert()"
                v-text="$t('warn_pay_balcao_pending')"
              />
              <section>
                <div
                  v-if="currentRouteName === 'dashboard'"
                  class="card-align-posts grid"
                >
                  <div
                    class="
                      col-12
                      p-2
                      pt-0
                      sm:p-3 sm:pt-0
                      md:col-8
                      lg:p-6 lg:pt-0
                    "
                  >
                    <h2>
                      {{ $t("welcome_message", { name: $root.user.name }) }}
                    </h2>
                    <Posts />
                  </div>
                  <div class="col-12 md:col-4">
                    <SidebarClient />
                  </div>
                </div>
                <div
                  v-else
                  :class="[
                    currentRouteName == 'post' ? 'card-align-posts' : 'post',
                    currentRouteName == 'process' ? 'card' : null,
                  ]"
                >
                  <router-view />
                </div>
              </section>  
            </div>
          </div>
        </div>
      </div>
      <Notifications
        :visible="visibleNotifications"
        @sidebar-closed="visibleNotifications = false"
        @number-of-notifications-changed="setNotificationsNumber"
      />
      <EditProfile
        :visible="visibleProfile"
        @sidebar-closed="visibleProfile = false"
      />
      <Toast :auto-z-index="false" />
    </BlockUI>
    <AddMissingData
      v-if="hasMissingData"
      :visible="hasMissingData"
      @dialog-closed="hasMissingData = false"
    />
  </div>
</template>

<script>
import BlockUI from "primevue/blockui";
import Posts from "../posts/Posts.vue";
import EditProfile from "../profile/EditProfile.vue";
import Notifications from "../profile/Notifications.vue";
import AddMissingData from "./AddMissingData.vue";
import DashboardSidebarMenu from "./DashboardSidebarMenu.vue";
import SidebarClient from "./SidebarClient.vue";
import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    ConfirmDialog,
    EditProfile,
    Notifications,
    Posts,
    DashboardSidebarMenu,
    BlockUI,
    AddMissingData,
    SidebarClient,
    Message,
  },
  data() {
    return {
      visibleNotifications: false,
      numberOfNotifications: 0,
      visibleProfile: false,
      showSidebar: true,
      showSmSidebar: false,
      contacts: [],
      hasMissingData: false,
      hasCardPaymentAlert: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.getHasMissingData();
    if (!this.contacts || this.contacts.length === 0) {
      this.$root
        .apiGet(this.$root.api.profile, { params: { dataType: "contacts" } })
        .then((response) => {
          this.contacts = response.data.data;
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.msg_code == "client_not_found"
          ) {
            this.$root.logout();
          } else {
            this.$root.toastError(error.response.data.msg_code);
          }
        });
    }
  },
  mounted() {
    this.getHasCardPaymentAlert();
    if (!!this.$root.user.hasBalcaoMais && !this.$root.user.cardImageUrl) {
      this.setCardImageUrl();
    }
  },
  methods: {
    setNotificationsNumber(number) {
      if (!isNaN(number)) {
        this.numberOfNotifications = number;
      }
    },
    setShowSidebar() {
      this.showSidebar = !this.showSidebar;
      if (document.body.offsetWidth < 1136) {
        this.showSmSidebar = true;
        this.showSidebar = false;
      }
    },
    resetShowSidebar() {
      this.showSmSidebar = false;
      this.showSidebar = true;
    },
    getHasMissingData() {
      this.$root
        .apiGet(this.$root.api.hasMissingData)
        .then((response) => {
          this.hasMissingData = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    getHasCardPaymentAlert() {
      this.$root.apiGet(this.$root.api.hasCardPaymentAlert).then((response) => {
        if (response.data.data) {
          this.hasCardPaymentAlert = true;
        }
      });
    },
    setHasCardPaymentAlert() {
      this.hasCardPaymentAlert = false;
      this.$root.apiPut(this.$root.api.hasCardPaymentAlert, {
        hasCardPaymentAlert: false,
      });
    },
    setCardImageUrl() {
      this.$root
        .apiPost(this.$root.api.cardImageUrl)
        .then(() =>
          this.$root.apiGet(this.$root.api.jwt).then((response) => {
            const jwt = response.data.data;
            if (jwt) {
              this.$store.commit("setToken", jwt);
              this.$root.axiosSetAuth(jwt, false);
            }
          })
        )
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    confirmLogout() {
      this.$confirm.require({
        group: "headless",
        header: this.$t("logout_header"),
        message: this.$t("logout_message"),
        rejectLabel: this.$t("bt_no"),
        acceptLabel: this.$t("bt_yes"),
        icon: "pi pi-question",
        acceptClass: "p-button p-component ml-2",
        rejectClass:
          "p-button p-component p-button-secondary p-button-outlined",
        accept: () => {
          this.$root.logout(); // Chama o método de logout se o usuário confirmar
        },
        reject: () => {
          //this.$root.toastSuccess("");
        },
      });
    },
  },
};
</script>
