<template>
  <div
    class="mb-3 p-3 block post-card"
    :class="{
      'post-card-admin': post.user && post.user.type !== 'Client',
      'post-card-warn': !post.user || post.postType === 'ProcessLog',
    }"
  >
    <div class="col-12 p-0">
      <div v-if="post.postType === 'Aniversários'">
        <small>
          {{ post.description }}
          <span>{{ $root.formatDate(post.createdAt) }}</span>
          <br />
        </small>
        <img :src="$root.images.birthDate" class="post-image-birthday" />
        <span class="text-center" v-html="$sanitize(post.message)" />
      </div>
      <div v-else class="space-between mb-2">
        <div v-if="post.user" class="flex w-full">
          <ShowUserImage
            v-if="post.user.type == 'Client'"
            :url="$root.getUrlFromFilePath(post.user.imgUrl)"
            width="50"
            height="50"
          />
          <div
            v-else-if="$root.user.hasBalcaoMais"
            class="img-user mr-2"
            :style="{
              backgroundImage: `url(${$root.images.balcaoMaisIcon})`,
              width: '50px',
              height: '50px',
            }"
          ></div>
          <div
            v-else
            class="img-user mr-2"
            :style="{
              backgroundImage: `url(${
                $root.user.realm === 'BE'
                  ? $root.images.beLogoBall
                  : $root.images.pafSymbol
              })`,
              width: '50px',
              height: '50px',
            }"
          ></div>
          <div>
            <div
              v-if="
                !fromProcess && post.processId && post.postType === 'Processos'
              "
              class="ml-2"
            >
              <b>{{ post.user.name }}</b
              ><span class="mx-1 lowercase">{{ $t("in_the_process") }}</span>
              <router-link
                :to="{ name: 'process', params: { id: post.processId } }"
                class="w-full block"
              >
                <strong>{{ post.processTypeName }}</strong>
              </router-link>
            </div>
            <span v-else class="ml-2">
              <strong>{{
                post.user.type == "Client"
                  ? post.user.name
                  : $root.user.hasBalcaoMais
                  ? "Balcão +"
                  : $root.user.realm == "BE"
                  ? "Balcão do Emigrante"
                  : "Grupo ParcialFinance"
              }}</strong>
            </span>
            <div v-if="post.postType === 'ProcessLog'" class="ml-2">
              {{ $t("in_the_process") }} <b>{{ post.processTypeName }}</b>
            </div>
            <small class="mt-1 ml-2 text-600 d-grid">{{
              $root.formatDateTime(post.createdAt)
            }}</small>
          </div>
        </div>
        <div v-else class="mb-2">
          <small>{{ $root.formatDateTime(post.createdAt) }}</small>
        </div>
      </div>
      <b>{{ post.description }}</b>
      <p
        class="mb-5 mt-2"
        style="overflow-wrap: break-word"
        v-html="$sanitize(post.message)"
      />
    </div>
    <div
      v-if="post.files && post.files.length > 0 && post.type !== 'Processos'"
      class="attach-box mb-6"
    >
      <h4 class="w-full pb-2 mb-4 title">{{ $t("attach_title") }}</h4>
      <PostFileGroup :files="post.files" @show-file="setDisplayFiles" />
    </div>
    <!--Comments-->
    <div class="mt-2">
      <div v-for="comment in post.comments" :key="comment.id" class="pt-1 flex">
        <div v-if="comment.user" class="mt-1">
          <ShowUserImage
            v-if="comment.user.type == 'Client'"
            :url="$root.getUrlFromFilePath(comment.user.imgUrl)"
          />
          <div
            v-else-if="$root.user.hasBalcaoMais"
            class="img-user mr-2"
            :style="{
              backgroundImage: `url(${$root.images.balcaoMaisIcon})`,
              width: '30px',
              height: '30px',
              border: '1px solid #e9e9e9',
            }"
          ></div>
          <div
            v-else
            class="img-user mr-2"
            :style="{
              backgroundImage: `url(${
                $root.user.realm === 'BE'
                  ? $root.images.beLogoBall
                  : $root.images.pafSymbol
              })`,
              width: '30px',
              height: '30px',
              border: '2px solid #e9e9e9',
            }"
          ></div>
        </div>
        <div
          class="ml-2 p-4 post-comment-card w-full word-break-all"
          :class="{ 'post-admin': comment.user.type !== 'Client' }"
        >
          <b class="mr-2">
            {{
              comment.user.type == "Client"
                ? comment.user.name
                : $root.user.hasBalcaoMais
                ? " Balcão+ "
                : $root.user.realm == "BE"
                ? "Balcão do Emigrante"
                : "Grupo ParcialFinance"
            }}
          </b>
          <b class="mr-1">·</b>
          <small
            >{{ $root.formatDate(comment.createdAt) }}
            {{ $root.formatTime(comment.createdAt) }}</small
          >
          <br />
          <p
            class="mb-0 mt-2"
            style="overflow-wrap: break-word"
            v-html="$sanitize(comment.message)"
          />
          <div
            v-if="comment.files && comment.files.length > 0"
            class="attach-box mt-6"
          >
            <h4 class="w-full pb-2 title mb-2">{{ $t("attach_title") }}</h4>
            <PostFileGroup
              :files="comment.files"
              @show-file="setDisplayFiles"
            />
          </div>
        </div>
      </div>
      <CreateComment
        v-if="
          !post.processId &&
          'receiver' in post &&
          post.receiver &&
          'clients' in post.receiver &&
          post.receiver.clients.length == 1 &&
          post.receiver.clients[0] == $root.user.name
        "
        :id="post.id"
        class="mt-2"
        @comment-created="getComments"
      />
      <div class="flex justify-content-end">
        <router-link
          v-if="post.processId && !fromProcess"
          :to="{ name: 'process', params: { id: post.processId } }"
          class="p-button p-component p-button-sm"
        >
          {{ $t("goto_process") }}
        </router-link>
      </div>
    </div>
    <DialogFiles
      :files="filesToDisplay"
      :display-files="displayFiles"
      :file-selected-index="fileSelectedIndex"
      @closed="displayFiles = false"
    />
  </div>
</template>

<script>
import DialogFiles from "../files/DialogFiles.vue";
import ShowUserImage from "../files/ShowUserImage.vue";
import PostFileGroup from "./PostFileGroup.vue";
import CreateComment from "./CreateComment.vue";

export default {
  components: { PostFileGroup, CreateComment, DialogFiles, ShowUserImage },
  props: {
    post: { type: Object, default: null },
    showRealUser: { type: Boolean, default: false },
    fromProcess: { type: Boolean, default: false },
  },
  data() {
    return {
      displayFiles: false,
      fileSelectedIndex: 0,
      filesToDisplay: [],
    };
  },
  computed: {
    showUser() {
      return (
        this.post.user.type == "Client" ||
        this.showRealUser ||
        this.post.postType == "Processos" ||
        (this.post.receiver &&
          "clients" in this.post.receiver &&
          this.post.receiver.clients.length == 1 &&
          this.post.receiver.clients[0] == this.$root.user.name)
      );
    },
  },
  methods: {
    getComments() {
      this.$root
        .apiGet(this.$root.api.postComments, {
          params: { postId: this.post.id },
        })
        .then((response) => {
          this.post.comments = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    setDisplayFiles(files, index) {
      this.filesToDisplay = files;
      this.fileSelectedIndex = index;
      this.displayFiles = true;
    },
  },
};
</script>
