<template>
  <footer :class="showKeepWindowOpen ? 'pt-6' : ''">
    <div class="sidebar-footer mt-3">
      <div class="grid">
        <div class="col-12 justify-content-end sm:col-6 sm:justify-content-start flex align-items-center">
          <router-link :to="{ name: 'cards-terms-and-conditions' }" class="terms-and-conditions-link" v-if="showTermsAndConditions && $root.user.hasBalcaoMais" @click="closeSidebar">
            <h5 class="text-right mb-0">Termos e condições Balcão+</h5>
          </router-link>
          <div v-if="showKeepWindowOpen" class="col-12 w-auto">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon bc-transparent">
                <Checkbox v-model="keepWindowOpen" binary />
              </span>
              <span class="p-inputgroup-addon">
                <label>{{ $t("keep_window_open") }}</label>
              </span>
            </div>
          </div>        
        </div>
        <div class="col-12 sm:col-6 flex justify-content-end">
          <Button
              :disabled="isLoading"
              :loading="isLoading"
              class="p-button p-component p-button-secondary p-button-outlined mr-2"
              icon="pi pi-times"
              :label="$t('close')"
              @click="cancel"
            />
            <Button
              :disabled="isLoading || !canSumbit"
              :loading="isLoading"
              :label="submitText"
              icon="pi pi-check"
              @click="submit"
            />

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    submitText: { type: String, default: null },
    showKeepWindowOpen: { type: Boolean, default: false },
    canSumbit: { type: Boolean, default: true },
    showTermsAndConditions: { type: Boolean, default: false },
  },
  emits: ["submit", "cancel"],
  data() {
    return { keepWindowOpen: false };
  },
  methods: {
    submit() {
      this.$emit("submit", this.keepWindowOpen);
    },
    cancel() {
      this.$emit("cancel");
    },
    closeSidebar() {
      this.$emit('cancel');
    }
  },
};
</script>
