<template>
    <div>
      <h1>{{ $t("trial_balances") }}</h1>
      <DataTable
        :value="trialBalances"
        :loading="isLoading"
        responsive-layout="scroll"
        class="
          p-datatable-striped p-datatable-gridlines p-datatable-sm
          pb-6
          d-grid
        "
      >
        <template #empty>
          <span>{{ $t("trial_balances_not_found") }}</span>
        </template>
        <Column field="year" :header="$t('year')" sortable />
        <Column field="month" :header="$t('month')" sortable>
          <template #body="slotProps">
            <span>
              {{ $t(`calendar.monthNames.${slotProps.data.month - 1}`) }}
            </span>
          </template>
        </Column>
        <Column field="message" :header="$t('message')" />
        <Column field="file" :header="$t('file')">
          <template #body="slotProps">
            <PostFile
              :file="slotProps.data.file"
              :display-name="true"
              class="file-manager-displayed-file d-contents"
              @show-file="setDisplayFile(slotProps.data.file)"
            />
          </template>
        </Column>
      </DataTable>
  
      <DialogFiles
        :display-files="showFile"
        :files="fileToDisplay ? [fileToDisplay] : []"
        @closed="showFile = false"
      />
    </div>
  </template>
  
  <script>
  import DataTable from "primevue/datatable";
  import DialogFiles from "../files/DialogFiles.vue";
  import PostFile from "../posts/PostFile.vue";
  
  export default {
    components: { DataTable, DialogFiles, PostFile },
    data() {
      return {
        trialBalances: [],
        isLoading: false,
        showFile: false,
        fileToDisplay: null,
      };
    },
    created() {
      this.getTrialBalances();
    },
    methods: {
      getTrialBalances() {
        this.isLoading = true;
        this.$root
          .apiGet(this.$root.api.trialBalances)
          .then((response) => {
            this.isLoading = false;
            this.trialBalances = response.data.data;
          })
          .catch((error) => {
            this.isLoading = false;
            this.$root.toastError(error.response.data.msg_code);
          });
      },
      setDisplayFile(file) {
        this.fileToDisplay = file;
        this.showFile = true;
      },
    },
  };
  </script>
  