/* eslint-disable no-undef */
const apiRoutes = {
  data() {
    return {
      api: {
        //fileManager
        fileManagerUrl: process.env.VUE_APP_FILE_MANAGER, //used to get files from /temp and /files
        fileManagerSave: process.env.VUE_APP_API_FILE_MANAGER + "save", //used by FileUpload
        fileManagerPersist: process.env.VUE_APP_API_FILE_MANAGER + "persist", //POST
        fileManagerFiles: process.env.VUE_APP_API_FILE_MANAGER + "files", //GET
        fileManagerFileName: process.env.VUE_APP_API_FILE_MANAGER + "file/name", //GET, PUT
        fileManagerClients: process.env.VUE_APP_API_FILE_MANAGER + "clients", //GET
        fileManagerAskDocumentationsSave:
          process.env.VUE_APP_API_FILE_MANAGER + "ask-documentations/save", //used by FileUpload
        fileManagerAskDocumentationsPersist:
          process.env.VUE_APP_API_FILE_MANAGER + "ask-documentations/persist", //POST
          fileManagerCardTermsAndConditions: process.env.VUE_APP_API_FILE_MANAGER + "client-card-terms-and-conditions", //GET

        //client
        profile: process.env.VUE_APP_API_ADMIN + "profile/clients", //PUT, GET
        clients: process.env.VUE_APP_API_ADMIN + "clients", //GET

        //clients credentials
        login: process.env.VUE_APP_API_ADMIN + "login/client/BE", //POST
        loginRgpd: process.env.VUE_APP_API_ADMIN + "login/client/BE/rgpd", //GET
        satisfactionSurvey:
          process.env.VUE_APP_API_ADMIN + "satisfaction-survey", //POST
        clientRgpds: process.env.VUE_APP_API_ADMIN + "client-rgpds",
        sendRecoverToken:
          process.env.VUE_APP_API_ADMIN + "recover-token/client", //POST
        tokenConfirmation:
          process.env.VUE_APP_API_ADMIN + "token-confirmation/client", //POST
        resetPassword: process.env.VUE_APP_API_ADMIN + "reset-password/client", //PUT
        askDocumentations:
          process.env.VUE_APP_API_ADMIN + "client-ask-documentations", //GET

        //cards
        cardSendRecoverToken: process.env.VUE_APP_API_ADMIN + "recover-token", //POST
        cardTokenConfirmation:
          process.env.VUE_APP_API_ADMIN + "token-confirmation", //POST
        cardImageUrl: process.env.VUE_APP_API_ADMIN + "cards/image-url", //POST

        notifications: process.env.VUE_APP_API_ADMIN + "notifications", //GET, PUT, DELETE

        jwt: process.env.VUE_APP_API_ADMIN + "jwt", //GET

        //posts
        posts: process.env.VUE_APP_API_ADMIN + "posts", //GET POST
        postFiles: process.env.VUE_APP_API_ADMIN + "post-files", //POST
        postComments: process.env.VUE_APP_API_ADMIN + "post-comments", //POST, GET
        postsAgendas: process.env.VUE_APP_API_ADMIN + "posts-agenda", //GET

        // processes
        processFileUrlsClient:
          process.env.VUE_APP_API_ADMIN + "process-file-urls-client", //GET
        processes: process.env.VUE_APP_API_ADMIN + "processes-client", //GET

        // appointments
        appointmentsCreateService:
          process.env.VUE_APP_ADMIN +
          "agendamento?type=area-de-cliente&jwtToken=", //Iframe

        appointments: process.env.VUE_APP_API_ADMIN + "appointments-client", //GET

        //externalApi

        externalApiBEmigrantePosts:
          "https://b-emigrante.com/wp-json/wp/v2/posts?categories=35&per_page=4&_fields=title,link,date_gmt,excerpt.rendered,_links,_embedded&_embed", //GET //Categoria: 35 - Artigos
        externalApiBussolaPosts:
          "https://abussola.b-emigrante.com/wp-json/wp/v2/pages?Magazine=true&status=publish&_fields=id,title,link,descricao,x_featured_media_large,x_metadata._elementor_data&per_page=100", //GET
        externalApiBussolaLastPost:
          "https://abussola.b-emigrante.com/wp-json/wp/v2/pages?Magazine=true&status=publish&_fields=id,title,link,descricao,x_featured_media_large,x_metadata._elementor_data&per_page=1", //GET
        externalApiBussolaThumbnail:
          "https://abussola.b-emigrante.com/wp-content/uploads/3d-flip-book/auto-thumbnails",

        countries: process.env.VUE_APP_API_ADMIN + "countries", //GET
        professions: process.env.VUE_APP_API_ADMIN + "professions", //GET
        states: process.env.VUE_APP_API_ADMIN + "states", //GET
        cities: process.env.VUE_APP_API_ADMIN + "cities", //GET

        clientSale: process.env.VUE_APP_API_ADMIN + "my-client-sales", //GET

        hasMissingData:
          process.env.VUE_APP_API_ADMIN + "profile/clients/has-missing-data", //GET
        hasCardPaymentAlert:
          process.env.VUE_APP_API_ADMIN +
          "profile/client-auth/has-card-payment-alert", //GET
        missingData:
          process.env.VUE_APP_API_ADMIN + "profile/clients/missing-data", //GET, PUT
        fileManagerOpportunity:
          process.env.VUE_APP_API_FILE_MANAGER + "opportunity", //GET

        webinars: process.env.VUE_APP_API_ADMIN + "webinars", //GET
        clientMedals: process.env.VUE_APP_API_ADMIN + "client-medals", //GET
        trialBalances: process.env.VUE_APP_API_ADMIN + "trial-balances-client", //GET
      },
    };
  },
  methods: {
    getApiPosts(id) {
      return `${this.api.posts}/${id}`; //GET
    },
    getApiFileName(id) {
      return `${this.api.fileManagerFileName}/${id}`; //GET, PUT
    },
    getApiClientFiles(clientId) {
      return `${this.api.fileManagerClients}/${clientId}`; //GET
    },
    getApiNotifications(id) {
      return `${this.api.notifications}/${id}`; //PUT, DELETE
    },
    getApiProcesses(id) {
      return `${this.api.processes}/${id}`; //GET
    },
    getApiFileManagerOpportunity(id) {
      return `${this.api.fileManagerOpportunity}/${id}`; //GET
    },
    getApiSatisfactionSurvey(token) {
      return `${this.api.satisfactionSurvey}/${token}`;
    },
    getApiClientRgpds(token) {
      //GET, POST
      return `${this.api.clientRgpds}/token/${token}`;
    },
    getApiExternalBussolaThumbnail(id) {
      return `${this.api.externalApiBussolaThumbnail}/${id}.png`; //GET
    },
    getApiAskDocumentation(token) {
      return `${this.api.askDocumentations}/${token}`; //GET
    },
    getApiAskDocumentationInputValues(token) {
      return `${this.api.askDocumentations}/${token}/input-values`; //PUT
    },
  },
};

export default apiRoutes;
